<!--  -->
<template>
  <div class="this-insurance-mall">
    <div class="head">
      <ul class="uls">
        <li>
          <img src="../../assets/image/fanhui.png" alt @click="toHome" />
        </li>
        <li style="text-align:center;">企业福利</li>
        <!-- <li style="text-align:center;">产品</li> -->
        <li></li>
      </ul>
      <!-- 搜索框 -->
      <div class="search">
        <div class="ipt">
          <img src="../../assets/image/sousuo.png" alt />
          <input
            type="text"
            v-model="shopName"
            @input="search"
            @focus="search"
            placeholder="请输入关键词进行搜索"
          />
          <img class="clears" v-if="isclear" src="../../assets/image/cleans.png" @click="clearAll" />
        </div>
        <!-- 右边功能 -->

        <div class="right">
          <ul>
            <li>
              <ol>
                <li @click="goOrder()">
                  <img src="../../assets/image/p__dingdan.png" alt />
                </li>
                <li>订单</li>
              </ol>
            </li>
            <li>
              <ol>
                <li @click="goShopCar()">
                  <img src="../../assets/image/p__gouwuche.png" alt />
                </li>
                <li>购物车</li>
              </ol>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 保险类型选择 -->
    <!-- 保险类型选择 -->
    <div class="content">
      <div class="type" v-if="isshow">
        <van-dropdown-menu active-color="#47a3ff">
          <van-dropdown-item v-model="value2" @change="int" title-class="baos" :options="option2" />
          <van-dropdown-item
            v-model="value3"
            @change="sanint"
            title-class="sans"
            :options="option3"
          />
        </van-dropdown-menu>
      </div>

      <!-- 筛选 -->
      <div class="over">
        <div class="screen" v-if="isshow">
          <ul>
            <li @click="newest()" :style="{background:(changeColor?'#f4f4f4':'#47a3ff')}">
              <div :style="{color:(changeColor?'#666':'#fff')}">最新上架</div>
            </li>
            <!-- <li>销量优先</li> -->
            <li @click="lowest()" :style="{background:(changeColor?'#47a3ff':'#f4f4f4')}">
              <div :style="{color:(changeColor?'#fff':'#666')}">价格最低</div>
            </li>
          </ul>
        </div>
        <!-- 保险列表 -->
        <!-- 保险列表  changeColor =true-->
        <div class="Insurance">
          <div class="recommend-items" v-if="insList.length>0">
            <div
              class="recommend-item"
              v-for="(item,index) in insList"
              :key="index"
              @click="tl(item.flProductId)"
              :style="{borderBottom:(index ==insList.length-1?'0':'1px solid #f5f5f5')} "
            >
              <div class="rec-left">
                <img :src="item.flProductHomeImage" @click="godetail(item.flProductId)" />
              </div>
              <div class="rec-right" @click="godetail(item.flProductId)">
                <div class="rec-top">
                  <span class="det-title">{{item.flProductName}}</span>
                  <img
                    class="rec-img"
                    src="../../assets/image/tuijian.png"
                    v-if="item.flProductHot==0"
                  />
                </div>
                <div class="rec-bottom">
                  <div class="univalent">
                    <span class="a">￥</span>
                    <span>{{item.flProductPrice}}</span>
                    <span>起</span>
                  </div>
                  <div class="tobuy" @click.stop="tobuyOne(item.flProductId)">
                    <img src="../../assets/image/jiagou.png" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty" v-if="insList.length<=0">
            <van-empty description="暂无信息" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Axios from 'axios'
import { DropdownMenu, DropdownItem, Field, Toast, Dialog } from 'vant'
import { mapState, mapActions } from 'vuex'
import { Empty } from 'vant'


Vue.use(Toast)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Empty)
Vue.use(Field)
Vue.use(Dialog)
export default {
  data () {
    return {
      changeColor: false,
      value1: 0,
      value2: 0,
      value3: 34,
      option1: [{
        text: '公司',
        value: 0
      }],
      option2: [{
        text: '分类',
        value: 0
      }],
      option3: [{
        text: '产品',
        value: 0
      }],
      insList: [],
      shopName: '',//关键字搜索
      time: '',//最新上架时间
      istime: true,
      isclear: false,
      isshow: true,
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {
    this.companyList()
    this.insuranceSlect()
    this.value2 = parseInt(this.$store.state.corporateWelfare.value2)
    this.value3 = parseInt(this.$store.state.corporateWelfare.value3)
    this.istime = this.$store.state.corporateWelfare.istime
    this.inst()
    if (this.value2 != 0) {
      this.CorinsuranceMallChilder({
        id: this.value2
      }).then(res => {
        if (res.code == 200) {

          if (res.data.length > 0) {
            this.option3 = res.data
            this.value3 = parseInt(this.$store.state.corporateWelfare.value3)
          }
          if (this.istime == true) {
            this.newest()
          } else {
            this.lowest()
          }
        }

      })
    }




  },
  beforeUpdate () {
    if (this.value2 != 0) {
      document.querySelector('.baos .van-ellipsis').style.color = "#47a3ff"
    }
    if (this.value3 != 0) {
      document.querySelector('.sans .van-ellipsis').style.color = "#47a3ff"
    }
  },
  methods: {
    ...mapActions('CrinsuranceMall', ['CorinsuranceshopList', 'CorinsuranceMall', 'CorinsuranceMallChilder', 'Corcompany']),
    ...mapActions('shopList', ['addCart']),
    ...mapActions('warranty', ['myFlOrder']),
    ...mapActions('shopList', ['getShopList']),
    //跳转订单
    goOrder () {
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {

        this.myFlOrder().then((res) => {
          if (res.code == 200) {
            this.$router.push({ name: 'myOrder', query: { order: 0 } })
          } else {
            Toast(res.msg)
          }
        })

      } else {
        Dialog.confirm({
          title: '系统提示',
          message: '请登录后操作!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })
        })
      }
    },
    // 跳转购物车

    goShopCar () {
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {

        this.getShopList().then(res => {
          if (res.code == 200) {
            localStorage.setItem('shopCarBack', '1')
            this.$router.push({ name: 'shopCarList' })
          } else {
            Toast(res.msg)
          }
        })

      } else {
        Dialog.confirm({
          title: '系统提示',
          message: '请重新登录!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      }

    },
    godetail (id) {
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
        localStorage.setItem('detailBack', '1')
        this.$router.push({ name: 'corwelDetail', query: { pid: id, back: 6 } })
      } else {
        localStorage.setItem('detailBack', '1')
        this.$router.push({ name: 'corwelDetail', query: { pid: id, back: 6 } })
        //     Dialog.confirm({
        //   title: '系统提示',
        //   message: '请登录后操作!',
        //   confirmButtonText:'确定',
        //   confirmButtonColor:"#5d6d8e",
        //   showCancelButton:false,
        //   className:'tishi'
        // }).then(() => {
        //   this.$router.push({
        //     name: 'Login'
        //   });

        // });
      }

    },
    // 加入购物车
    tobuyOne (id) {
      if (localStorage.getItem('token') != '' && localStorage.getItem('token') != null) {
        this.addCart({
          pid: id
        }).then(res => {
          if (res.code == 200) {
            Toast({
              duration: 500, // 持续展示 toast
              message: '加入购物车成功',
            })
          } else {
            Toast(res.msg)
          }
        })
      } else {
        Dialog.confirm({
          title: '系统提示',
          message: '请登录后操作!',
          confirmButtonText: '确定',
          confirmButtonColor: "#5d6d8e",
          showCancelButton: false,
          className: 'tishi'
        }).then(() => {
          this.$router.push({
            name: 'Login'
          })

        })
      }
    },
    // 完成
    sanint () {
      this.$store.commit('corporateWelfare2', { value3: this.value3 })
      document.querySelector('.sans .van-ellipsis').style.color = "#47a3ff"
      if (this.istime == true) {
        this.newest()

      } else {
        this.lowest()
      }
    },
    tl (bxShopUrl) {
      // window.location.href =bxShopUrl;
    },

    //公司列表下拉
    companyList (id) {
      this.Corcompany()
        .then((res) => {
          var opt = [{
            text: '公司',
            value: 0
          }]
          this.option1 = opt.concat(res.data)
        })
    },
    insuranceSlect () { // 父列表
      this.CorinsuranceMall().then((res) => {
        if (res.code == 200) {
          var pot2 = [{
            text: '分类',
            value: 0
          }]
          this.option2 = pot2.concat(res.data)
          if (this.option2.length > 0) {
            document.querySelector('.baos .van-ellipsis').style.color = "#323233"
          }
        }

      })
    },
    toHome () {
      this.$router.push({ path: '/' })
    },
    inst () {//保险列表
      if (this.istime == true) {
        this.newest()
      } else {
        this.lowest()
      }
    },
    int (id) {//联动子接口
      this.$store.commit('corporateWelfare1', { value2: this.value2 })
      if (id != 0) {
        document.querySelector('.baos .van-ellipsis').style.color = "#47a3ff"
        this.CorinsuranceMallChilder({
          id: id
        })
          .then(res => {
            if (res.code == 200) {

              if (res.data.length > 0) {
                this.option3 = res.data
                this.value3 = res.data[0].value
                this.$store.commit('corporateWelfare2', { value3: this.value3 })
              }
              if (this.istime == true) {
                this.newest()
              } else {
                this.lowest()
              }
            }
          })

      } else {
        this.option3 = [{
          text: '产品',
          value: 0
        }]
        this.value3 = 0
        if (this.istime == true) {
          this.newest()
        } else {
          this.lowest()
        }

      }
      //获取子类id刷新页面
    },
    clearAll () {
      this.shopName = ''
      this.isclear = false
      if (this.istime == true) {
        this.newest()
      } else {
        this.lowest()
      }

    },
    search () {
      if (this.shopName != '') {
        this.isclear = true
      } else {
        this.isclear = false
      }
      if (this.istime == true) {
        this.newest()
      } else {
        this.lowest()
      }
    },
    //  最新上架
    newest () {
      this.changeColor = false
      this.istime = true
      this.$store.commit('corporateWelfare4', this.istime)
      this.CorinsuranceshopList({
        flProductSort: 0,
        flProductParentId: this.value2,
        flProductChildId: this.value3,
        flProductName: this.shopName
      }).then(res => {
        if (res.code == 200) {
          this.insList = res.rows
        }

      })


    },
    //   价格最低
    lowest () {
      this.changeColor = true
      this.istime = false
      this.$store.commit('corporateWelfare4', this.istime)
      this.CorinsuranceshopList({
        flProductSort: 1,
        flProductParentId: this.value2,
        flProductChildId: this.value3,
        flProductName: this.shopName
      }).then(res => {
        if (res.code == 200) {
          this.insList = res.rows
        }
      })
    },

  },

  // 生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
};
</script>
<style scoped>
.tobuy{
  width: 1rem;
}
.a{margin-top: 0.06rem;

}
  /* @import url(); 引入css类 */
  .this-insurance-mall {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 0.3rem;
  }

  .head {
    height: 2.1rem;
    width:100%;
    /* z-index:-10; */
    /* zoom: 1; */
    background-color: #fff;
    background: url(../../assets/image/beijing.png);
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
  }
    /* 悬浮窗 */
    .suspension{
      width: 100%;
      z-index:5;
      position: fixed;

    }
    .suspension ul {
      background: #fff;
      border:1px solid red;
      height: 2rem;
    }
     .suspension ul li {
        border-bottom: 1px solid red;
     }

  .uls {
    display: flex;
    justify-content: space-around;
    height: 1rem;
    line-height: 1rem;
    color: #ffffff;
  }
.uls img{
    width:0.18rem;
    margin-left: 0.1rem;
    margin-top: 0.35rem;
}
  .uls li {
    font-size: 0.34rem;
    width: 30%;

  }
  /* 搜索 */
  .search{
      display: flex;
      justify-content: space-around;
  }
  .search .ipt{
    margin-bottom: 0.4rem;
      width: 85%;
      height: 0.7rem;
      /* margin-left: 0.2rem; */
      font-size: 0.28rem;
      display: flex;
      align-items: center;
        background: rgba(255, 255, 255, 0.3);
         border-radius: 0.4rem;
  }
  .search .ipt img{
    width:0.28rem;
    height:0.28rem;
    display: block;
    flex-shrink: 0;
    margin-left:0.55rem;
    margin-right:0.12rem

  }
  ::-webkit-input-placeholder{color:#ffffff;}
  .search .ipt input{
    margin-top: -0.02rem;
 font-size: 0.3rem;
 border: 0;
      height: 0.7rem;
      width: 90%;
      color: #ffffff;

      background: rgba(255,255,255,0);
  }
  .search .right{
      width: 24%;
      margin-top: 0.1rem;

      color: #ffffff;
  }
  .search .right ul{
    margin-bottom: 0.4rem;
      display: flex;
      justify-content: space-around;
          width: 100%;
  }
  .search .right ul>li {
      /* border: 1px solid red; */
      font-size: 0.25rem;
  }
  .search .right img{
    display: block;
      width: 0.35rem;
      height: 0.35rem;
      margin: 0 auto;
  }
  /* 保险类型选择 */
  /* 保险类型选择 */
  .content {
    width: 100%;
    height:100%;
      display: flex;
    flex-direction: column;

  }

  .content .type {
    height: 1rem;
    font-family: PingFang-SC-Bold;
    color: #333333;
    flex-shrink: 0;
  }

  .type  .van-dropdown-menu__bar {
    box-shadow: 0 0 0 0;
  }

  .type .van-dropdown-menu__title::after {
    position: absolute;
    top: 70%;
    right: -4px;
    margin-top: -5px;
    border: 3px solid;
    border-color: transparent transparent #999999 #999999;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    opacity: .8;
    content: '';
  }

  /* 保险筛选 */
  .content .screen {
    height: auto;
    width: 95%;
    margin-left: 0.18rem;
    font-family: PingFang-SC-Regular;
    display: block;
    flex-shrink: 0;
    padding-bottom: 0.4rem;
  }

  .content .screen ul {
    display: flex;
    justify-content: space-around;
    color: #333333;
  }

  .content .screen ul li {

    /* line-height: 1rem; */
    /* border:1px solid red; */
    text-align: center;
    background: #f4f4f4;
    color: #666;
    padding: 0.12rem 0.38rem;
    font-size: 0.26rem;
    border-radius: 0.04rem;
  }

  /* 保险列表 */
 .Insurance {
    width:90%;
    margin:0 auto;
    height:100%;
  }
  .over{
   height:100%;
    display: flex;
    flex-direction: column;
     overflow-y:auto ;
    -webkit-overflow-scrolling: touch;

  }

  .InsuranceList {
    width: 95%;
    position: relative;
    line-height: 2.3rem;
    height: 2.3rem;
    border-bottom: 1px solid #dfdad6;
    margin-left: 0.2rem;
    /* padding-top:-0.5rem; */
    box-sizing: border-box;
    margin-top: 0.3rem;
  }

  .InsuranceList .tuijian {
    width: 10%;
    position: absolute;
    right: 0.42rem;
    top: 0;
  }

  .InsuranceList div {
    width: 90%;
    height: 2rem;
    margin-left: 0.3rem;
    display: flex;
    /* margin-top: -0.1rem; */
    box-sizing: border-box;
    /* border: 1px solid red; */
    /* justify-content: space-between; */
  }

  .InsuranceList div img {
    width: 32%;
  }

  .InsuranceList div ul {
    margin-left: 0.3rem;
  }

  .InsuranceList div ul li {
    height: 1rem;
    line-height: 1rem;
    font-family: PingFang-SC-Bold;
    font-weight: 550;
    color: #666666;
  }

  .InsuranceList div ul .jiagou ol {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .InsuranceList div ul .jiagou ol li {
    color: #f5591b;

  }

  .InsuranceList div ul .jiagou ol img {
    width: 15%;
    position: absolute;
    top: 0.22rem;
    /* right: 0.1rem; */
  }
  .top{
    background: none;
    position: relative;
    background: (255,255,255,0);
  }
  /* 修改商城ckk */
  .search{
    display:flex;
    align-items: center;
    width:95%;
    margin:0 auto;
    justify-content: space-between;
  }
    .recommend-item {
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 0.4rem;
    display: flex;
    margin-bottom: 0.4rem

  }
  .rec-left {
    width: 33%;
    height: 1.6rem;
    border-radius: 0.08rem;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 3%;
  }

  .rec-left img {
    height: 100%;
    width: 100%;
    display: block;
  }
  .rec-right{
        width: 64%;
  }
  .rec-det{
    width: 100%;
    display: flex;
  }

  .rec-img{
    width: 0.66rem;
    height:0.66rem;
    display: block;
    flex-shrink: 0;

  }
    .insure-Info, .rec-top {
    display: flex;
    justify-content: space-between;
  }
 .det-title {
    font-size: 0.3rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #666666;
    margin-top:0.2rem;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    width:100%
  }
  .rec-bottom {
    display: flex;
    justify-content: space-between;
    height: auto;
    margin-top:0.3rem;
        align-items: center;

  }
.univalent {
    display: flex;
    height: auto;
    align-items:baseline;
  }

  .univalent span:nth-child(1) {
    font-size: 0.3rem;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0rem;
    color: #f5591b;
    font-family: PingFang-SC-Bold;

  }

  .univalent span:nth-child(2) {
    font-size: 0.38rem;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #f5591b;
    font-family: PingFang-SC-Bold;
    /* margin-top:0.04rem */

  }

  .univalent span:nth-child(3) {
    font-size: 0.24rem;
    font-weight: normal;
    letter-spacing: 0rem;
    color: #f5591b;
    /* margin-top: 0.08rem; */
    margin-left: 0.02rem;
    font-family: PingFang-SC-Bold;
  }
  .empty{
    margin-top:1rem
  }
  .clears{
    width:0.28rem;
    height:0.27rem;
    flex-shrink: 0;
    margin-left:0.25rem !important;
    margin-right:0.3rem !important;
    display: block;
  }
    .search .right{
      width: 23%;
      height:0.7rem;
      padding-left: 0.24rem;
      color: #ffffff;
      display: flex;
      align-items: center;
      flex-shrink: 0;
  }
  .search .right ul{
      display: flex;
      align-items: center;
  }
  .search .right ul>li {
      /* border: 1px solid red; */
      font-size: 0.25rem;
  }
  .search .right img{
      height: 0.3rem;
      margin: 0 auto;
  }
  .first{
    margin-right:0.3rem
  }
  .tobuy img{
    float: right;
    width: 0.6rem;
	height: 0.6rem;
  }
  .empty{
    margin-top:1rem
  }
  .clears{
    width:0.28rem;
    height:0.27rem;
    flex-shrink: 0;
    margin-left:0.25rem !important;
    margin-right:0.3rem !important;
    display: block;
  }
</style>
